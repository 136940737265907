<template>
  <div>
    <SettingsNavigation :links="nestedLinks" />

    <div class="Settings__Children">
      <Authorize :permission="$route.meta.permission || true">
        <router-view />
      </Authorize>
    </div>
  </div>
</template>

<script>
import Authorize from '@/components/core/Authorize.vue'
import SettingsNavigation from '@/pages/settings/components/SettingsNavigation.vue'

export default {
  components: {
    Authorize,
    SettingsNavigation
  },

  computed: {
    nestedLinks () {
      return [
        {
          label: this.$t('pages.settings.company.navigation.organisation'),
          to: { name: 'settings.company.organisation' },
          icon: 'organisation'
        },
        {
          label: this.$t('pages.settings.company.navigation.export-clippings'),
          to: { name: 'settings.company.export-organisation-clippings' },
          icon: 'save'
        },
        {
          label: this.$t('pages.settings.company.navigation.trend-groups'),
          to: { name: 'settings.company.trend-groups' },
          icon: 'organisation'
        }
      ]
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/vueStyleUtils.scss';

.SettingsNavigation__slot {
  position: absolute;
  right: 0;
  top: 0;
}
</style>
